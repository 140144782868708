import { NextSeo, SoftwareAppJsonLd } from 'next-seo';
import useSWR from 'swr';
import { AppMetaDocument } from '@/generated/contentful';
import { client } from '@/lib/contentful/client';
import { getBaseUrl, serializeCacheKey } from '@/lib/contentful/utils';
import { shouldAppReviewTemporarilyHidden } from '@/utils/apps/constants';
import { getSeoLocale } from '@/utils/locales/getSeoLocale';
import { useUrl } from '@/utils/url/useUrl';
type Props = {
  locale: string | undefined;
  slug: string;
  children: React.ReactNode;
};
export function AppMeta({
  locale = 'en-US',
  slug,
  children
}: Props) {
  const {
    data
  } = useSWR(slug && serializeCacheKey('appMeta', {
    slug,
    locale
  }), () => client().request(AppMetaDocument, {
    where: {
      slug
    }
  }));
  const url = useUrl({
    locale
  });
  const baseUrl = `${url?.protocol}//${url?.host}`;
  const entry = data?.marketplaceAppCollection?.items?.[0];
  if (!entry) return <>{children}</>;
  const title = entry.seoMetadata?.title?.endsWith('| BigCommerce') ? entry.seoMetadata.title : `${entry.seoMetadata?.title} | BigCommerce`;
  return <>
			<SoftwareAppJsonLd name={entry.name ?? ''} price={(entry.upfrontPrice !== '0' ? entry.upfrontPrice : entry.monthlyPrice) ?? '0'} priceCurrency="USD" aggregateRating={!shouldAppReviewTemporarilyHidden(entry.name) && entry.rating && entry.reviewCount ? {
      ratingValue: entry.rating,
      reviewCount: entry.reviewCount
    } : undefined} operatingSystem="Cloud, SaaS, Web-Based" applicationCategory="BusinessApplication" data-sentry-element="SoftwareAppJsonLd" data-sentry-source-file="AppMeta.tsx" />

			<NextSeo title={title} description={entry.seoMetadata?.description ?? undefined} canonical={entry.seoMetadata?.canonicalUrl || `${baseUrl}/apps/${slug}/`} languageAlternates={[{
      hrefLang: 'en-au',
      href: `${getBaseUrl('en-AU')}/apps/${slug}/`
    }, {
      hrefLang: 'en-gb',
      href: `${getBaseUrl('en-GB')}/apps/${slug}/`
    }, {
      hrefLang: 'en-us',
      href: `${getBaseUrl('en-US')}/apps/${slug}/`
    }, {
      hrefLang: 'x-default',
      href: `${getBaseUrl()}/apps/${slug}/`
    }]} openGraph={{
      title: title,
      description: entry.seoMetadata?.description ?? undefined,
      url: `${baseUrl}/apps/${slug}/`,
      siteName: 'BigCommerce',
      images: entry.seoMetadata?.image?.url ? [{
        url: entry.seoMetadata?.image?.url,
        width: entry.seoMetadata?.image?.width,
        height: entry.seoMetadata?.image?.height,
        alt: 'Bigcommerce logo',
        type: 'image/jpeg'
      }] : undefined,
      locale: getSeoLocale(locale)
    }} nofollow={Boolean(entry.seoMetadata?.nofollow)} noindex={Boolean(entry?.seoMetadata?.noindex)} data-sentry-element="NextSeo" data-sentry-source-file="AppMeta.tsx" />

			{children}
		</>;
}